/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewConfigIcon from '@material-ui/icons/ViewComfy';
import ListIcon from '@material-ui/icons/List';
import Label from 'src/components/Label';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SystemUpdateAltTwoToneIcon from '@material-ui/icons/SystemUpdateAltTwoTone';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EventSeatSharpIcon from '@material-ui/icons/EventSeatSharp';
import ViewListSharpIcon from '@material-ui/icons/ViewListSharp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

export default [
  {
    subheader: 'Pages',
    items: [
      // {
      //   title: 'Overview',
      //   href: '/overview',
      //   icon: HomeIcon
      // },
      // {
      //   title: 'Dashboard',
      //   href: '/dashboards/default',
      //   icon: DashboardIcon
      // },
      {
        title: 'Product Master',
        href: '/management/productMaster',
        icon: StorefrontIcon,
        access: 1
      },

      // {
      //   title: 'Dashboards',
      //   href: '/dashboards',
      //   icon: DashboardIcon,
      //   items: [
      //     {
      //       title: 'Default',
      //       href: '/dashboards/default'
      //     },
      //     {
      //       title: 'Analytics',
      //       href: '/dashboards/analytics'
      //     }
      //   ]
      // },
      {
        title: 'Sync',
        href: '/management/dataSync',
        icon: SystemUpdateAltTwoToneIcon,
        access: 6
      },
      {
        title: 'Relist',
        href: '/management/relist',
        icon: EventSeatSharpIcon,
        access: 2
      },
      // {
      //   title: 'Repricing',
      //   href: '/management/repricing',
      //   icon: MonetizationOnIcon,
      //   access: 44
      // },
      {
        title: 'Add New Products',
        href: '/management/addNewProduct',
        icon: AddCircleOutlineIcon,
        access: 4
      },
      {
        title: 'Manual',
        href: '/manual',
        icon: FitnessCenterIcon,
        items: [
          {
            title: 'Repricing Generator',
            href: '/manual/repricingGenerator',
            access: 44
          },
          {
            title: 'Repricing Import',
            href: '/manual/repricingImport',
            access: 44
          },
          {
            title: 'Relist Import',
            href: '/manual/relistImport',
            access: 47
          },
        ]
      },
      {
        title: 'Listing',
        href: '/listing',
        icon: ShoppingCartIcon,
        items: [
          {
            title: 'GOAT Listing',
            href: '/listing/goat',
            access: 8
          },
          {
            title: 'Consign Listing',
            href: '/listing/consign',
            access: 9
          },
          {
            title: 'Shopify Listing',
            href: '/listing/shopify',
            access: 11
          },
          {
            title: 'Exception Listing',
            href: '/listing/exception',
            access: 16
          },
          {
            title: 'Orders Listing',
            href: '/listing/orders',
            access: 17
          },
          {
            title: 'SRP Listing',
            href: '/listing/srp',
            access: 18
          }
        ]
      },
      {
        title: 'Logs',
        href: '/log',
        icon: ViewListSharpIcon,
        items: [
          {
            title: 'QTY Update Log',
            href: '/log/qtyUpdate',
            access: 19
          },
          {
            title: 'SKU Not Match Log',
            href: '/log/skuNotMatch',
            access: 20
          },
          {
            title: 'Repricing Log',
            href: '/log/repricing',
            access: 46
          },
          {
            title: 'Relist Log',
            href: '/log/relist',
            access: 21
          },
          {
            title: 'Relist Review Log',
            href: '/log/relistReviewLog',
            access: 49
          },
          {
            title: 'Add New Products Log',
            href: '/log/addNewProduct',
            access: 22
          },
          {
            title: 'Remove Product Log',
            href: '/log/removeProduct',
            access: 23
          }
        ]
      },
      {
        title: 'Report',
        href: '/report',
        icon: AssessmentIcon,
        items: [
          {
            title: 'Chino Inventory',
            href: '/report/chinoInventoryReport',
            access: 38
          },
          {
            title: 'SMSneaker Sales',
            href: '/report/smsneakerSales',
            access: 31
          },
          {
            title: 'ebaylaboys Sales',
            href: '/report/ebaylaboysSalesReport',
            access: 34
          },
          // {
          //   title: 'Inventory Check Report',
          //   href: '/report/inventoryCheck',
          //   access: 37
          // },
          {
            title: 'ebaylaboys Inventory',
            href: '/report/ebaylaboysInventory',
            access: 41
          }
        ]
      },
      {
        title: 'Accounts',
        href: '/account',
        icon: AccountBoxIcon,
        items: [
          {
            title: 'User List',
            href: '/account/userList',
            access: 'management only'
          },
          {
            title: 'Group List',
            href: '/account/groupList',
            access: 'admin only'
          },
          {
            title: 'Register',
            href: '/account/register',
            access: 'management only'
          },
          {
            title: 'Change Password',
            href: '/account/changePassword'
          },
          {
            title: 'Login Log',
            href: '/account/loginLog',
            access: 28
          },
        ]
      },
    ]
  }
];
