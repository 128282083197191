import mock from 'src/utils/mock';
import axios from 'axios';
import url from './url'

//login and logout
mock.onPost('/api/login').reply(function (config) {
  return axios.post(url + 'users/login', JSON.parse(config.data).params)
})

mock.onPost('/api/logout').reply(function (config) {
  return axios.post(url + 'users/logout', JSON.parse(config.data).params)
})

//users
mock.onGet('/api/getUserList').reply(function (config) {
  return axios.get(url + 'users/getUserList?username=' + config.params.username)
})

mock.onGet('/api/getUser').reply(function (config) {
  return axios.get(url + 'users/getUser?username=' + config.params.username + '&query_username=' + config.params.query_username)
})

mock.onPost('/api/addUser').reply(function (config) {
  return axios.post(url + 'users/addUser', JSON.parse(config.data).params)
})

mock.onPost('/api/adjustUser').reply(function (config) {
  return axios.post(url + 'users/adjustUser', JSON.parse(config.data).params)
})

mock.onPost('/api/removeUser').reply(function (config) {
  return axios.post(url + 'users/removeUser', JSON.parse(config.data).params)
})

//groups
mock.onGet('/api/getGroups').reply(function (config) {
  const urlWithParams = url + 'users/getGroups?username=' + config.params.username
  return axios.get(urlWithParams)
})

mock.onGet('/api/getGroupDetail').reply(function (config) {
  const urlWithParams = url + 'users/getGroupDetail?username=' + config.params.username + '&group_id=' + config.params.group_id
  return axios.get(urlWithParams)
})

mock.onPost('/api/adjustGroup').reply(function (config) {
  return axios.post(url + 'users/adjustGroup', JSON.parse(config.data).params)
})

mock.onPost('/api/addGroup').reply(function (config) {
  return axios.post(url + 'users/addGroup', JSON.parse(config.data).params)
})

mock.onPost('/api/removeGroup').reply(function (config) {
  return axios.post(url + 'users/removeGroup', JSON.parse(config.data).params)
})


