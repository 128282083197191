import { CognitoUserPool } from 'amazon-cognito-identity-js'
import AWS from 'aws-sdk'
import axios from 'src/utils/axios';

AWS.config.update({
    region: "us-west-2",
    accessKeyId: "AKIAWFG3JHVZZZUDQ4H2", secretAccessKey: "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"
});

export const poolData = {
    UserPoolId: 'us-west-2_r7UIHuK0t',
    ClientId: '16p302ujf81o52r22ithe3qomc'
}

export const UserPool = new CognitoUserPool(poolData)
export const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

export const getUsername = () => {
    const cognitoUser = UserPool.getCurrentUser()
    cognitoUser.getSession(function (err, session) {
        if (err) {
            alert(err.message);
        }
        const exp = session.accessToken.payload.auth_time + 57600
        const now = new Date().getTime() / 1000

        if (now > exp){
            cognitoUser.signOut()
        }
    });
    return cognitoUser.username

    

}

export const checkAccess = (access, setAccess) => {
    axios.get("/api/getNavBar", { params: { username: getUsername() } }).then((response) => {
        const functions = response.data.user.functions
        const role = response.data.user.role

        if (role !== 99) {
            for (const key in access) {
                if (functions.indexOf(access[key].access_id) >= 0) {
                    access[key].available = true
                }
            }
        } else {
            for (const key in access) {
                access[key].available = true
            }
        }

        setAccess({ ...access })

    });
}






