import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import Topbar from './Topbar';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  },
  loadingMask: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: "1300",
    display: "none"
  },
  circleFrame: {
    display: "flex",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
}));

function Auth({ route }) {
  const classes = useStyles();

  return (
    <>
      <div id="loadingMask" className={classes.loadingMask}>
        <div className={classes.circleFrame}>
          <CircularProgress className={classes.circle} />
        </div>
      </div>
      <Topbar />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
}

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;
