import mock from 'src/utils/mock';
import axios from 'axios';
import url from './url'

//repricing
mock.onGet('/api/getRepricing').reply(function (config) {
  return axios.get(url + 'repricing/?username=' + config.params.username)
});

mock.onGet('/api/getRepricingReport').reply(function (config) {
  return axios.get(url + 'repricing/getRepricingReport?username=' + config.params.username)
});
mock.onPost('/api/removeRepricingReport').reply(function (config) {
  return axios.post(url + 'repricing/removeRepricingReport', JSON.parse(config.data).params)
});

mock.onGet('/api/getRepricingRecord').reply(function (config) {
  return axios.get(url + 'repricing/getRepricingRecord?username=' + config.params.username)
});

mock.onGet('/api/getRepricingLog').reply(function (config) {
  return axios.get(url + 'repricing/getRepricingLog?username=' + config.params.username)
});

mock.onPost('/api/generateRepricingManual').reply(function (config) {
  return axios.post(url + 'repricing/generate', JSON.parse(config.data).params)
});

mock.onPost('/api/generateRepricingManual2').reply(function (config) {
  return axios.post(url + 'repricing/generate2', JSON.parse(config.data).params)
});

mock.onPost('/api/importRepricingManual').reply(function (config) {
  return axios.post(url + 'repricing/manualImport', JSON.parse(config.data).params)
});

//relist
mock.onGet('/api/getRelistProducts').reply(function (config) {
  return axios.get(url + 'restock/getRelist?username=' + config.params.username)
});

mock.onGet('/api/getRelistHistory').reply(function (config) {
  const urlWithParams = url + 'restock/getRelistHistory?sku=' + config.params.sku + '&username=' + config.params.username
  return axios.get(urlWithParams)
});

mock.onGet('/api/getRelistRecord').reply(function (config) {
  return axios.get(url + 'restock/getRelistRecord?username=' + config.params.username)
});

mock.onPost('/api/restockAdjustRelist').reply(function (config) {
  return axios.post(url + 'restock/adjustRelist', JSON.parse(config.data).params)
})

mock.onPost('/api/importRelistManual').reply(function (config) {
  return axios.post(url + 'restock/manualImport', JSON.parse(config.data).params)
});

//add new products
mock.onGet('/api/getListNew').reply(function (config) {
  return axios.get(url + 'restock/getListNew?username=' + config.params.username)
})

mock.onGet('/api/getListNewVariants').reply(function (config) {
  const urlWithParams = url + 'restock/getListNewVariants?style=' + config.params.style + '&username=' + config.params.username
  return axios.get(urlWithParams)
  // return axios.post(url + 'restock/getListNewVariants', JSON.parse(config.data).params)
})

mock.onPost('/api/listNew').reply(function (config) {
  return axios.post(url + 'restock/listNew', JSON.parse(config.data).params)
})

//data sync
mock.onGet('/api/getProductLog').reply(function (config) {
  return axios.get(url + 'product/getLog?username=' + config.params.username)
});

mock.onPost('/api/saveItems').reply(function (config) {
  return axios.post(url + 'product/saveItems', JSON.parse(config.data).params)
});

//product listing
mock.onGet('/api/getGoat').reply(function (config) {
  return axios.get(url + 'goat/getProducts?username=' + config.params.username)
});

mock.onGet('/api/getGoatWithParams').reply(function (config) {
  const urlWithParams = url + 'goat/getProducts?source_id=' + config.params.source_id + '&username=' + config.params.username
  return axios.get(urlWithParams)
})

//consign listing
mock.onGet('/api/getGoatConsignedList').reply(function (config) {
  return axios.get(url + 'goat/getConsignedProducts?username=' + config.params.username)
});

mock.onPost('/api/updateConsignedProducts').reply(function (config) {
  return axios.post(url + 'goat/updateConsignedProducts', JSON.parse(config.data).params)
})

//shopify listing
mock.onGet('/api/getShopify').reply(function (config) {
  return axios.get(url + 'shopify/getProducts?username=' + config.params.username)
});

mock.onPost('/api/removeProduct').reply(function (config) {
  return axios.post(url + 'shopify/removeProduct', JSON.parse(config.data).params)
})

mock.onGet('/api/getProductVariants').reply(function (config) {
  const urlWithParams = url + 'shopify/getProductVariants?product_id=' + config.params.product_id + '&username=' + config.params.username
  return axios.get(urlWithParams)
});

//exception listing
mock.onGet('/api/getExceptionList').reply(function (config) {
  return axios.get(url + 'exceptions/getProducts?username=' + config.params.username)
});

mock.onPost('/api/exceptionAddProduct').reply(function (config) {
  return axios.post(url + 'exceptions/addProduct', JSON.parse(config.data).params)
})

mock.onPost('/api/exceptionAdjustProduct').reply(function (config) {
  return axios.post(url + 'exceptions/adjustProduct', JSON.parse(config.data).params)
})

mock.onPost('/api/exceptionRemoveProduct').reply(function (config) {
  return axios.post(url + 'exceptions/removeProduct', JSON.parse(config.data).params)
})

//orders
mock.onGet('/api/getOrderList').reply(function (config) {
  return axios.get(url + 'orders/getOrders?username=' + config.params.username)
});

//SRP
mock.onGet('/api/getSRP').reply(function (config) {
  return axios.get(url + 'srp/getSRP?username=' + config.params.username)
});

//QTY update log
mock.onGet('/api/getWriteOff').reply(function (config) {
  return axios.get(url + 'qtyUpdate/getWriteOff?username=' + config.params.username)
});

//SKU not match log
mock.onGet('/api/getWriteOffCheck').reply(function (config) {
  return axios.get(url + 'qtyUpdate/getWriteOffCheck?username=' + config.params.username)
});

//relist log
mock.onGet('/api/getRelistLog').reply(function (config) {
  return axios.get(url + 'restock/getRelistLog?username=' + config.params.username)
});

//add new product log
mock.onGet('/api/getListNewLog').reply(function (config) {
  return axios.get(url + 'restock/getListNewLog?username=' + config.params.username)
})

//remove product log
mock.onGet('/api/getRemoveLog').reply(function (config) {
  return axios.get(url + 'shopify/getRemoveLog?username=' + config.params.username)
})

//relist review log
mock.onGet('/api/getRelistReviewLog').reply(function (config) {
  return axios.get(url + 'goat/getReviewProducts?username=' + config.params.username)
})

//chino inventory report
mock.onGet('/api/getChinoInventoryReport').reply(function (config) {
  return axios.get(url + 'reports/getChinoInventoryReport?username=' + config.params.username)
}); 

//SMSneaker sales report
mock.onPost('/api/reportsGenerateSMSneaker').reply(function (config) {
  return axios.post(url + 'reports/generateSMSneaker', JSON.parse(config.data).params)
})

mock.onPost('/api/reportsRemoveSMSneaker').reply(function (config) {
  return axios.post(url + 'reports/removeSMSneakerReport', JSON.parse(config.data).params)
})

mock.onGet('/api/getReportsSMSneaker').reply(function (config) {
  return axios.get(url + 'reports/getSMSneakerReports?username=' + config.params.username)
});

//other Merchant sales report
mock.onPost('/api/reportsGenerateEBaylaboys').reply(function (config) {
  return axios.post(url + 'reports/generateEBaylaboys', JSON.parse(config.data).params)
})

mock.onPost('/api/reportsRemove').reply(function (config) {
  return axios.post(url + 'reports/removeReport', JSON.parse(config.data).params)
})

mock.onGet('/api/getReportsEbaylaboys').reply(function (config) {
  return axios.get(url + 'reports/getEbaylaboysReports?username=' + config.params.username)
});

//inventory check sales report
mock.onPost('/api/generateInventoryCheck').reply(function (config) {
  return axios.post(url + 'reports/generateInventoryCheck', JSON.parse(config.data).params)
})

mock.onPost('/api/removeInventoryCheck').reply(function (config) {
  return axios.post(url + 'reports/removeInventoryCheck', JSON.parse(config.data).params)
})

mock.onGet('/api/getInventoryCheck').reply(function (config) {
  return axios.get(url + 'reports/getInventoryCheck?username=' + config.params.username)
});

//Ebaylaboys inventory report
mock.onPost('/api/generateEbaylaboysInventory').reply(function (config) {
  return axios.post(url + 'reports/generateEbaylaboysInventory', JSON.parse(config.data).params)
})

mock.onPost('/api/removeEbaylaboysInventory').reply(function (config) {
  return axios.post(url + 'reports/removeEbaylaboysInventory', JSON.parse(config.data).params)
})

mock.onGet('/api/getEbaylaboysInventory').reply(function (config) {
  return axios.get(url + 'reports/getEbaylaboysInventory?username=' + config.params.username)
});

//login log
mock.onGet('/api/getLoginLog').reply(function (config) {
  return axios.get(url + 'users/getLoginLog?username=' + config.params.username)
});